import React, { useEffect, useState, useRef } from 'react';
import { isSidebarOpen } from '../adminMenu/sidemenustate';
import { blue, blueGrey } from '@mui/material/colors';
import { TextareaAutosize, Box, Container } from "@mui/material";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from '../../SnackbarContext';
import contentcopy from '../../assets/icons/content_copy.svg';
import { useAuth } from '../auth/AuthProvider';
import { Navigate, useNavigate } from 'react-router-dom';

const CodeInstall: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [copied, setCopied] = useState(false);
  const { openSnackbar } = useSnackbar(); 
  const { signOut, userDetails } = useAuth();
  const Navigate = useNavigate();
  const getOrgIdDetails = localStorage.getItem("userInfo") || '{}';  
  const parsedData = JSON.parse(getOrgIdDetails);
  if (Object.keys(parsedData).length === 0 || !parsedData.orgDetails || !JSON.parse(parsedData.orgDetails).OrganizationId) {
   // localStorage.clear();
    signOut();
    Navigate("/login");
  } 
  const orgId = JSON.parse(parsedData.orgDetails).OrganizationId;
  const accountId = localStorage.getItem("CurrentAccountId");
  let userWebUrl = process.env.REACT_APP_UER_WEB
  const scriptContent = `
  (function (g, u, i, d, e, s) {
    g[e] = g[e] || []
    g.AccountId = s;
    var f = u.getElementsByTagName(i)[0]
    var k = u.createElement(i);
    k.async = true;
    k.src = '${userWebUrl}/embeded.js';
    f.parentNode.insertBefore(k, f);
    k.onload = function() {
    if (g.captureUserDetails) {
      g.captureUserDetails({
          OrganizationId: 'organization_Id',  // add value of organization id 
          UserId: 'user_id',                // add value of login user  id 
          UserName: '',                     // add value of login user name 
          EmailId: '',                      // add value of login user email
          UserType: '',                     //add value of login user type
          ScreenResolution : '',             //add value of screen resolution
          SessionId: '',                     //add value of session Id
          TimeZone: ''                        //add value of timezone
      });
    }
  }
  })(window, document, 'script', 'guiding', 'layer', '${accountId}');
 `;
  
  const copyCode = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = scriptContent;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [scriptContent]);

  // Trigger snackbar when 'copied' becomes true
  useEffect(() => {
    if (copied) {
      openSnackbar("Code has been copied!", "success");
    }
  }, [copied, openSnackbar]);

  // Note: The second useEffect for textareaRef seems unnecessary since TextareaAutosize isn't used,
  // but I'll leave it as is for completeness unless you remove TextareaAutosize entirely.
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.value = scriptContent;
    }
  }, [scriptContent]);

  return (
    <Container>
      <div className="qadpt-midpart setng-box">
        <div className="qadpt-content-block">
          <div className="qadpt-head">
            <div className="qadpt-title-sec">
              <div className="qadpt-title">Installation</div>
              <div className="qadpt-description">
                <div>These are the installation steps.</div>
              </div>
            </div>
            <div className="qadpt-right-part"></div>
          </div>
          <Box className="qadpt-content-box qadpt-cdinst">
            <div className="qadpt-stdata">
              <div>
                To enhance your experience and access all the features of our software, please follow
                these simple steps to install our Chrome plugin. The process is quick and easy, and
                you'll be up and running in no time!
              </div>
              <div className="qadpt-steps">
                <div className="qadpt-subdata">
                  <div>1. Download the Plugin:</div>
                  <ul>
                    <li>Visit the Chrome Web Store and search for [Plugin Name]. Click "Add to Chrome" to start the download.</li>
                  </ul>
                </div>
                <div className="qadpt-subdata">
                  <div>2. Enter the Code:</div>
                  <ul>
                    <li>After installation, click on the plugin icon in your browser toolbar. Enter the code we provided in the designated field to activate the full feature.</li>
                  </ul>
                </div>
                <div className="qadpt-subdata">
                  <div>3. Verify Installation:</div>
                  <ul>
                    <li>Refresh your browser to ensure the plugin is fully activated. A confirmation message or an updated icon will confirm that the plugin is ready for use.</li>
                  </ul>
                </div>
                <div>
                  Enjoy the enhanced functionality and smoother interface with our dedicated plugin!
                </div>
              </div>
            </div>
            <div className="qadpt-codehdr">Installation Code</div>
            <pre>
              <code>{scriptContent}</code>
            </pre>
            <div className="qadpt-codehdrbt">
              <CopyToClipboard text={scriptContent} onCopy={copyCode}>
                <button aria-disabled="true">
                  <img src={contentcopy} alt="copy" /> Copy Code
                </button>
              </CopyToClipboard>
              {/* Removed the ternary operator here */}
            </div>
          </Box>
        </div>
      </div>
    </Container>
  );
};

export default CodeInstall;