import React, { useState, useEffect, useTransition, ReactNode } from "react";
import {
  Dashboard,
  Audience,
  Tours,
  Announcements,
  Tooltips,
  Banners,
  webbanner,
  Checklist,
  Surveys,
  Hotspots,
  Settings,
  Auditlog,
  Filemanagement,
  Cursor
} from "../../assets/icons/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Popup from "../common/Popup";
import ButtonBase from '@mui/material/ButtonBase';
import { isSidebarOpen, setSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useRtl } from "../../RtlContext";
import { Divider, Tooltip as MuiTooltip } from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";
import { useTranslation } from "react-i18next";


interface CursorTooltipProps {
  title: string;
  children: ReactNode;
}

const CursorTooltip: React.FC<CursorTooltipProps> = ({ title, children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const handleMouseEnter = (): void => {
    setVisible(true);
    document.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseLeave = (): void => {
    setVisible(false);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseMove = (e: MouseEvent): void => {
    const gap = 15; 
    setPosition({
      x: e.clientX + gap,
      y: e.clientY + gap
    });
  };

  return (
    <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {visible && (
        <div
          style={{
            position: 'fixed',
            left: `${position.x}px`,
            top: `${position.y}px`,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#fff',
            color: '#000',
            fontSize: '12px',
            width: '248px',
            borderRadius: '8px',
            padding: '16px',
            borderTop: '2px solid #5c80ac',
            zIndex: 9999,
            pointerEvents: 'none'
          }}
        >
          {title}
        </div>
      )}
    </span>
  );
};

interface SideMenuProps {
  selectedLanguageProp: string;
}

const SideMenu: React.FC<SideMenuProps> = ({ selectedLanguageProp }) => {
  const { t: translate } = useTranslation();
  const [activeItem, setActiveItem] = useState<string>("");
  const [openPopup, setOpenPopup] = useState(false);
  const [sidebarOpen, setLocalSidebarOpen] = useState(isSidebarOpen());
  const [translatedTitles, setTranslatedTitles] = useState<{ [key: string]: string }>({});
  const { signOut, userDetails } = useAuth();
  const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { isRtl, toggleRtl } = useRtl();

  const themeWithDirection = createTheme({
    direction: isRtl ? "rtl" : "ltr",
  });

  useEffect(() => {
    const userInfoString = localStorage.getItem("userInfo");  
    if (userInfoString) { 
      try {
        const userInfo = JSON.parse(userInfoString);  
        if (userInfo['user']) {
          const parsedUser = JSON.parse(userInfo['user']);
          setUser(parsedUser);  
          if (parsedUser) {
            const OrgId = parsedUser.OrganizationId ?? '';
            setOrganizationId(OrgId);
          }
        }
      } catch (error) {
        console.error("Error parsing userInfo: ", error);
      }
    }
    else if (userDetails) {
      setUser(userDetails);  
      if (userDetails) {
        const OrgId = userDetails.OrganizationId ?? '';
        setOrganizationId(OrgId);
      }
    }
  }, []);

  useEffect(() => {
    const unsubscribe = subscribe(setLocalSidebarOpen);
    return () => unsubscribe();
  }, []);

  const MenuClick = (key: string) => {    
    if (!OrganizationId)
    {
      const userInfoString = localStorage.getItem("userInfo");  
      if (userInfoString) { 
        try {
          const userInfo = JSON.parse(userInfoString);  
          if (userInfo['user']) {
            const parsedUser = JSON.parse(userInfo['user']);
            setUser(parsedUser);  
            if (parsedUser) {
              const OrgId = parsedUser.OrganizationId ?? '';
              setOrganizationId(OrgId);
            }
          }
        } catch (error) {
          console.error("Error parsing userInfo: ", error);
        }
      }
    }
    
    setActiveItem(key);
    switch (key.toLowerCase()) {
      case "dashboard":
        return navigate("/dashboard");
      case "audience":
        return navigate("/audience");
      case "tours":
        return navigate("/tours");
      case "announcements":
        return navigate("/announcements");
      case "tooltips":
        return navigate("/tooltips");
      case "banners":
        return navigate("/banners");
      case "checklists":
        return navigate("/checklists");
      case "surveys":
        return navigate("/surveys");
      case "hotspots":
        return navigate("/hotspots");
      case "settings":
        return navigate(`${OrganizationId}/team`);        
      case 'filemanagement':
        return navigate("/filelist");
      default:
        return navigate("/");
    }
  };

  const toggleSidebar = () => {
    setLocalSidebarOpen(!sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  if (location.pathname === "/Builder") {
    return null;
  }

  const menuItems = [
    { key: "Announcements", icon: Announcements, title: translate('Announcements'), tooltip: 'Helps to communicate important updates, notifications, or messages.' },
    { key: "Banners", icon: webbanner, title: translate('Banners'), tooltip: 'Displays notifications at the top of the screen.' },
    { key: "Tooltips", icon: Tooltips, title: translate('Tooltips'), tooltip: 'Provide quick explanations, tips, or instructions of the tools,' },
    { key: "Hotspots", icon: Hotspots, title: translate('Hotspots'), tooltip: 'Interactive areas to draw attention to important features, actions, or guidance.' },
    { key: "Tours", icon: Tours, title: translate('Product Tour'), tooltip: 'Step-by-step guides to navigate and understand key features.' },
    { key: "Settings", icon: Settings, title: translate('Settings'), tooltip: 'Configure application settings and preferences.' },
    { key: "Checklists", icon: Checklist, title: translate('Checklists'), disabled: false, tooltip: 'Task lists that guide users through a series of steps or actions.' },
    { key: "Surveys", icon: Surveys, title: translate('Surveys'), disabled: true, tooltip: 'Interactive forms or questionnaires designed to collect feedback, insights, or opinions' },
    { key: "Dashboard", icon: Dashboard, title: translate('Dashboard'), disabled: true, tooltip: 'Get a unified view of insights and actions in one place.' },
    { key: "Audience", icon: Audience, title: translate('Audience'), disabled: true, tooltip: 'Categorize users into segments for precise targeting.' },
    { key: "Filemanagement", icon: Filemanagement, title: translate('File Management'), disabled: true, tooltip: 'Store and reuse media assets across multiple interactions.' },
  ];
  
  return (
    <ThemeProvider theme={themeWithDirection}>
      <CssBaseline />
      <div className="qadpt-side-menu">
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="persistent"
          anchor={isRtl ? "right" : "left"}
          open={sidebarOpen}
          className="qadpt-smenu"
          sx={{
            "& .MuiDrawer-paper": {
              direction: isRtl ? "rtl" : "ltr",
            },
          }}
        >
          <PerfectScrollbar>
          <List className="qadpt-smenu-list">
            {menuItems.map(({ key, icon, title, disabled, tooltip }, index) => (
              <React.Fragment key={key}>
                <CursorTooltip title={tooltip}>
                  <span>
                    <ButtonBase 
                      className={`qadpt-sm-item ${activeItem === key ? 'active' : 'inactive'} ${disabled ? 'disabled' : ''}`}
                      onClick={() => MenuClick(key)}
								disabled={disabled}
								disableRipple
                    >
                      <Box sx={{ textAlign: 'left', width: '100%' }}>
                        {disabled && (
                          <Typography
                            className="cmg-soon"
                            sx={{
                              textAlign: 'start',
                              marginBottom: '4px', 
                              opacity: 1, 
                            }}
                          >
                            Coming soon
                          </Typography>
                        )}
                      
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            opacity: disabled ? 0.5 : 1,
                            '& p': {
                              fontSize: '14px', 
                            },    
                          }}
                        >
                          <ListItemIcon
                            className={`qadpt-sm-icon ${activeItem === key ? 'active' : 'inactive'}`}
                            sx={{
                              ...(key === 'Banners' && { marginLeft: '-3px' }),
                            }}
                          >
                            <img src={icon} alt={key} />
                          </ListItemIcon>

                          <Typography>
                            {translatedTitles[title] || title}
                          </Typography>
                        </Box>
                      </Box>
                    </ButtonBase>
                  </span>
                </CursorTooltip>
              </React.Fragment>
            ))}
          </List>
          </PerfectScrollbar>  
        </Drawer>
        
        {openPopup && (
          <Popup
            onClose={() => setOpenPopup(false)}
            onOk={() => setOpenPopup(false)}
            type="Apply"
            title="Log out from QuickAdopt"
            description="Do you really want to logout?"
            button1="Cancel"
            button2="Logout"
          />
        )}
      </Box>
      </div>
    </ThemeProvider>
  );
};

export default SideMenu;